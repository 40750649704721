.PortfolioCard {
  padding: 8px 24px;
  border: 4px white solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  height: 100%;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.8),
    black 35%,
    black 70%,
    rgba(0, 0, 0, 0.8)
  );
  h1 {
    font-size: 36px;
    margin-bottom: 8px;
    text-align: center;
  }
  p {
    text-align: center;
  }
}

.MyRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
}

.PortfolioImage {
  max-width: 90%;
}

.Subheading {
  text-align: center;
}
