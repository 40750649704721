.Subheading {
  background-color: black;
  border: 4px white solid;
  border-radius: 12px;
  padding: 16px;
  h4 {
    margin-top: 12px;
  }
}

.PortfolioSection {
  h2 {
    text-align: center;
    background-color: black;
    border: 4px white solid;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    grid-column: 1/3;
  }
}
