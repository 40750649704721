.NavBar {
  background-color: #373d3f;
  height: 100%;
}

.NavList {
  color: white;
  list-style: none;
}

a {
  color: white;
}

a:hover {
  transition: 0.3s;

  color: #ff8ae4;
  text-decoration: none;
}

.WordNav > a {
  font-size: 16px;
}

.UpperCaseLetter {
  font-size: 24px;
}

.NavListItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PseudoBorder {
  background-image: linear-gradient(#ff8ae4, #9149d6);
  max-height: 100vh;
}

.PortfolioLink {
  transition: 0.3s;
  color: #9149d6;
}

.Active > a {
  color: #ff8ae4;
}
