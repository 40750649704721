.SkillsList {
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    height: 38px;

    margin: 8px;
    padding: 3px;
    border-radius: 19px;

    background-image: linear-gradient(45deg, #ff8ae4, #9149d6);
    background-color: #ff8ae4;

    list-style: none;
    button {
      display: inline-block;
      height: 32px;
      min-width: 80px;

      font-weight: bolder;
      font-size: 20px;

      padding: 3px 12px;
      border: 1px black solid;
      border-radius: 16px;

      color: black;
      background-color: white;

      cursor: pointer;
      &:active {
        color: white;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
  .Active {
    button {
      color: black;
      background-color: transparent;
      border-color: transparent;
    }
  }
}
