html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  font-weight: bolder;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: white;
}

p,
li,
ul,
ol,
div {
  color: white;
}

body {
  background-color: #373d3f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(291,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23ff8ae4'/%3E%3Cstop offset='1' stop-color='%239149d6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(300,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23ff8ae4'/%3E%3Cstop offset='1' stop-color='%239149d6'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='19.47'%3E%3Cpath transform='translate(-21 4.8) rotate(3 1409 581) scale(1.012)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='6.490000000000001' transform='translate(-12 12) rotate(3.6 800 450) scale(1.006)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(10.8 -36) rotate(36 401 736) scale(1.006)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='5.9'%3E%3Cpath transform='translate(72 -4.8) rotate(1.2 150 345) scale(0.988)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='12.980000000000002' transform='translate(-48 -30) rotate(43.2 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-72 24) rotate(7.2 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /* background by SVGBackgrounds.com */
  overflow-x: hidden;
}

h1 {
  font-size: 48px;
}

h3 {
  font-size: 28px;
}

p,
div {
  font-size: 20px;
}
