@media (min-width: 701px) and (max-width: 1000px) {
  .App {
    display: grid;
    height: 100vh;
    grid-template-rows: 96px 1fr;
  }

  .Main {
    margin: auto;
    grid-template-columns: 1fr 300px;
    display: grid;
    overflow-y: scroll;
    height: 100%;

    h1 {
      display: flex;
      font-size: 68px;
      grid-column-start: 1;
    }
    div {
      grid-column: 1/3;
      grid-row-start: 2;
    }
  }

  .PortfolioMain {
    overflow-y: scroll;

    .Subheading {
      margin: 48px;
    }
  }

  .ProfilePic {
    grid-row: 1;
    grid-column-start: 2;
    margin: 12px;
    width: calc(100% - 24px);
    display: flex;
    align-self: center;
  }

  .PortfolioSection {
    margin: 48px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;
  }

  .PseudoBorder {
    grid-row-start: 1;
    height: 96px;
  }

  .NavList {
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;
  }

  .NavBar {
    height: calc(100% - 4px);
  }

  .NavListItem {
    height: 100%;
    width: 100%;
  }
}
