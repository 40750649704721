.Main {
  padding: 12px;
  padding-top: 4px;
  margin-top: 4px;
  border: 4px white solid;
  border-radius: 16px;
  background-image: linear-gradient(rgb(0, 0, 0, 0.8), black 75%);
  overflow-x: hidden;
}

.ProfilePic {
  max-width: 100%;
  border-radius: 50%;
  border: 4px white solid;
}
