@media (min-width: 1px) and (max-width: 700px) {
  .App {
    display: grid;
    height: calc(100vh - 116px);
  }

  .Main {
    display: grid;
    height: calc(100vh - 216px - 8px);
    overflow-y: scroll;
    h1 {
      margin-bottom: 0;
      grid-row: 1;
    }
    div {
      margin-top: 1vw;
    }
  }

  .PortfolioMain {
    display: grid;
    height: calc(100vh - 216px - 8px);
    overflow-y: scroll;
    .Subheading {
      margin: 48px 0;
    }
  }

  .ProfilePic {
    grid-row: 2;
    margin: 24px;
    width: calc(100% - 48px);
  }

  .PortfolioSection {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;
  }

  .PseudoBorder {
    padding-bottom: 4px;
    grid-row-start: 1;
    height: 103px;
  }

  .NavBar {
    padding-bottom: 12px;
  }

  .NavList {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  .NavListItem {
    min-width: 25%;
    height: 50%;
  }

  .WordNav {
    min-width: 50%;
  }
}
